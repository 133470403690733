import ErrorView from "@/components/Views/Error";
import { Box, Container } from "theme-ui";

const NotFoundPage = () => {
  return (
    <Container
      sx={{
        minHeight: "calc(100vh - 7rem)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: "265px", textAlign: "center" }}>
        <ErrorView />
      </Box>
    </Container>
  );
};

export default NotFoundPage;
