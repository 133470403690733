import { Box, Text, Divider } from "theme-ui";

// theming
import { primaryBlue } from "../../utils/helpers";
import { theme } from "../../themes/base";

// helpers

interface Props {
  title?: string;
  extraContent?: React.ReactNode | null;
}
const ErrorView = ({
  title = "Something went wrong",
  extraContent = null,
}: Props) => {
  return (
    <>
      <Text sx={{ fontSize: "24px", fontWeight: 600 }}>{title}</Text>
      <Divider sx={{ color: theme.colors.charcoal20 }} />
      <Text
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "26px",
          color: theme.colors.darkCharcoal,
          fontFamily: "Libre Franklin",
        }}
      >
        Please reload the page, and if the problem persists, contact us at{" "}
        <a
          href="mailto:clientsupport@getretain.com"
          target="_blank"
          rel="noopener"
          style={{ color: primaryBlue }}
        >
          clientsupport@getretain.com
        </a>
      </Text>
      {extraContent}
    </>
  );
};

export default ErrorView;
